@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

:root {
    --black: #000000;
    --white: #FFFFFF;
    --primary: #1C212D;
    --litegreen: #81FACA;
    --secondary: #1C212D;
    --litgray: #2e303b;
    --pink: #DE12E6;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
}

#root, html {
    background: #F7F7F7;
}

.bg_img {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

}

.bg_img:before {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1C1F23 100%);
    width: 100%;
    height: 700px;
    position: absolute;
    left: 00px;
    bottom: 0px;

}

.p_100 {
    padding: 50px 100px 250px;

}

.react-multi-carousel-dot-list {
    bottom: 210px;
    left: 100px;
    justify-content: left;

}

.react-multiple-carousel__arrow {
    display: none;
}

.content_txt {
    z-index: 99;
    position: relative;
}

.content_txt .date {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: var(--white);
    margin-bottom: 10px;
}

.content_txt h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 44px;
    color: var(--white);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.content_txt p {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    color: var(--white);
    margin-bottom: 20px;
}

.content_txt a {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;
    color: var(--white);
}

.react-multi-carousel-dot button {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1C1F23 100%);
}

.react-multi-carousel-dot--active button {
    background: var(--white);
}

.react-multi-carousel-dot button {
    width: 15px;
    height: 15px;
}

.login_section {
    position: fixed;
    right: 0px;
    top: 0px;
    width: 40%;
    height: 100vh;
    background: rgba(41, 48, 57, 0.95);
    z-index: 1;
    padding: 0px 20px 0px 50px;
}

.login_section .div_login {
    padding: 0px 120px 0px 0px;
}

.div_login .logo_small {
    margin-bottom: 20px;
}

.div_login h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    color: var(--white);
    margin-bottom: 30px;
}

.div_login h1 span {
    font-weight: 700;
}

.div_login p {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: var(--white);
    margin-bottom: 40px;
}

.form_box {
    height: 88px;
    background: #242831;
    border-radius: 0px;
    border: 0px;
    margin: 0px;
    padding: 15px 88px 15px 88px;
    position: relative;
}

.form-label {
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    color: var(--white);
    margin-bottom: 5px;
}

.form_box .form-control {
    border: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 40px;
    height: 28px;
    color: var(--white);
    padding: 0px;
    background: transparent;
}

.form_box.danger .form-control {
    color: #f5697a;
}

.form_box .svg_icon {
    width: 88px;
    height: 88px;
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_box .icon_last {
    width: 88px;
    height: 88px;
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
}

.form_box:hover {
    background: #2E3442;
}

.form_box:focus-within {
    background: #2E3442;
}

.main_form .form_box.first {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.main_form .form_box.last {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0px solid transparent;
    -webkit-text-fill-color: var(--white);
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.form_check {
    padding: 20px 10px;

}

.form_check .form-check-label {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    color: var(--white);
    cursor: pointer;
}


.form_check .form-check-input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 100%;
    outline: none;
    box-shadow: none;
}

.form_check .form-check-input:checked {
    background-color: #6EC9A8;
    border-color: #6EC9A8;
}

.check_box {
    position: relative;
}

.forget_pass {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    color: var(--white);
    text-decoration: none;
    position: absolute;
    right: 10px;
    top: 20px;
}

.forget_pass:hover {
    color: var(--white);
    text-decoration: underline;
}

.form-submit {
    margin: 0px 0px;
    display: flex;
}

.form-submit input[type=submit] {
    width: 95px;
    background-color: #0178E5;
    height: 40px;
    line-height: 40px;
    padding: 0px;
    border: 0px;
    border-radius: 5px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--white);
    margin-right: 20px;
}

.form-submit a {
    display: inline-block;
    border: 1px solid var(--white);
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: var(--white);
    padding: 0px 20px;
    text-decoration: none;
    border-radius: 5px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.login_bottom {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    color: var(--white);
    position: absolute;
    bottom: 30px;
}

.login_bottom a {
    color: var(--white);
    text-decoration: none;
    font-weight: 600;
}

.login_bottom a:hover {
    text-decoration: underline;
    font-weight: 600;
}

/*old Work*/
.login_logo span {
    font-size: 24px;
    display: block;
    line-height: 30px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    font-size: 10px;
    opacity: .5;
}


.login_logo img {
    width: 100%;
    height: 46px;
    object-fit: cover;
}

.google_login {
    width: 300px;
    height: 50px;
    background: rgba(39, 55, 115, 1);
    box-shadow: 0px 3px 6px #000000FD;
    border: 1px solid #DE12E6;
    border-radius: 8px;
    line-height: 45px;
    margin: 100px auto 0px;

}

.google_login a {
    font-size: 20px;
    color: var(--white);
    text-transform: capitalize;
    text-decoration: none;
    height: 48px;
    display: block;
    border-radius: 8px;
}

.google_login img {
    width: 30px;
    height: 30px;
    margin-right: 30px;
}

.copyright {
    display: block;
    color: #fff;
    font-size: 12px;
    opacity: .8;
    position: absolute;
    bottom: 50px;
    left: 100px;
    font-family: 'Source Sans Pro', sans-serif;
}

.copyright p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: var(--white);
    margin-bottom: 10px;
}

.copyright ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.copyright ul li {
    display: inline-block;
    position: relative;
    margin-right: 30px;

}

.copyright ul li a:after {
    content: '';
    background: var(--white);
    width: 1px;
    height: 20px;
    display: block;
    position: absolute;
    top: 5px;
    right: -15px;
}

.copyright ul li:last-child a:after {
    display: none;
}

.copyright ul li a {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    color: var(--white);
}

.copyright ul li a:hover {
    color: #0178E5;
}

.layout-fixed.main-sidebar {
    bottom: 0;
    float: none;
    left: 0;
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: visible;
    z-index: 1038;
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
    width: 300px;
    background: var(--primary) 0% 0% no-repeat padding-box;
    box-shadow: 2px 0px 6px #00152959;

}

.arrow_icon {
    position: absolute;
    top: 26px;
    right: -10px;
    cursor: pointer;
    transform: rotate(180deg);
}

body.body_class .arrow_icon {
    transform: rotate(0deg);
}

.main_page_sectoin {
    margin-left: 330px;
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
    margin-top: 0px;
    padding-right: 30px;
}

.menu_sidebar .accordion__button {
    background: transparent;
    padding: 0px 0px;
    height: 45px;
    line-height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.menu_sidebar .accordion__button a {
    display: flex;
}

.menu_sidebar .accordion__button img {
    width: 20px;
    height: 21px;
    margin-right: 25px;
    margin-left: 25px;
}

.menu_sidebar .accordion__button:hover {
    background-color: var(--primary);
}

.menu_sidebar .accordion__button img {
    display: none;
}

.menu_sidebar accordion__button[aria-expanded='true'], .menu_sidebar .accordion__button[aria-selected='true'], .menu_sidebar .accordion__button:hover, .menu_sidebar .accordion__button:hover {
    background: #161B27;
}

.menu_sidebar .accordion__panel ul li:hover, .menu_sidebar .accordion__panel ul li.active {
    background: #161B27;
}

.menu_sidebar .accordion__panel ul li:hover:before, .menu_sidebar .accordion__panel ul li.active:before {
    content: '';
    height: 100%;
    width: 3px;
    background: #0178E5;
    position: absolute;
    left: 0px;
    top: 0px;
}

.menu_sidebar .accordion__button[aria-expanded='true'] span, .menu_sidebar .accordion__button[aria-selected='true'] span {
    color: #0178E5;
}

.menu_sidebar .accordion__button:hover span {
    color: #0178E5;
}

.menu_sidebar .accordion__button[aria-expanded='true'] img.icon1_blue, .menu_sidebar .accordion__button[aria-selected='true'] img.icon1_blue, .menu_sidebar .accordion__button[aria-expanded='true'] img.icon2_blue, .menu_sidebar .accordion__button[aria-selected='true'] img.icon2_blue, .menu_sidebar .accordion__button[aria-expanded='true'] img.icon3_blue, .menu_sidebar .accordion__button[aria-selected='true'] img.icon3_blue, .menu_sidebar .accordion__button[aria-expanded='true'] img.icon4_blue, .menu_sidebar .accordion__button[aria-selected='true'] img.icon4_blue, .menu_sidebar .accordion__button[aria-expanded='true'] img.icon5_blue, .menu_sidebar .accordion__button[aria-selected='true'] img.icon5_blue {
    display: block;
}

.menu_sidebar .accordion__button[aria-expanded='false'] img.icon1, .menu_sidebar .accordion__button[aria-selected='false'] img.icon1,
.menu_sidebar .accordion__button[aria-expanded='false'] img.icon2, .menu_sidebar .accordion__button[aria-selected='false'] img.icon2,
.menu_sidebar .accordion__button[aria-expanded='false'] img.icon3, .menu_sidebar .accordion__button[aria-selected='false'] img.icon3,
.menu_sidebar .accordion__button[aria-expanded='false'] img.icon4, .menu_sidebar .accordion__button[aria-selected='false'] img.icon4,
.menu_sidebar .accordion__button[aria-expanded='false'] img.icon5, .menu_sidebar .accordion__button[aria-selected='false'] img.icon5 {
    display: block;
}

.menu_sidebar .accordion__button:hover img.icon1,
.menu_sidebar .accordion__button:hover img.icon2,
.menu_sidebar .accordion__button:hover img.icon3,
.menu_sidebar .accordion__button:hover img.icon4,
.menu_sidebar .accordion__button:hover img.icon5 {
    display: none;
}

.menu_sidebar .accordion__button:hover img.icon1_blue,
.menu_sidebar .accordion__button:hover img.icon2_blue,
.menu_sidebar .accordion__button:hover img.icon3_blue,
.menu_sidebar .accordion__button:hover img.icon4_blue,
.menu_sidebar .accordion__button:hover img.icon5_blue {
    display: block;
}

.logo_sidebar {
    text-align: center;
    margin: 15px auto;
}

.logo_sidebar img.logo_main {
    width: auto;
    height: 45px;
    display: block;
    margin: 0px 20px;
    text-align: center;
}

.logo_sidebar img.logo_icon {
    display: none;
}

.menu_sidebar .accordion__button:before {
    display: none;
}

.menu_sidebar .accordion__panel {
    background: var(--secondary);
}

.menu_sidebar .accordion__panel ul {
    margin: 0px;
    list-style: none;
    padding: 0px;
    background: var(--secondary);
}

.menu_sidebar .accordion__panel ul li {
    padding: 0px 10px 0px 80px;
    background: var(--secondary) 0% 0% no-repeat padding-box;
    height: 40px;
    margin-bottom: 5px;
    line-height: 40px;
    position: relative;
}


.menu_sidebar .accordion__panel ul li a {
    display: block;
    text-align: left;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #646C7C;
    text-decoration: none;
}


.menu_sidebar .accordion__panel ul li.active a, .menu_sidebar .accordion__panel ul li:hover a {
    color: #0178E5;
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
}

.menu_sidebar .accordion {
    border: 0px;
    height: 85vh;
}

.menu_sidebar .accordion__panel {
    padding: 0px 0px;
    animation: fadein 0.35s ease-in;
}

.menu_sidebar .accordion__item + .accordion__item {
    border: 0px;
}

.logout_div {
    background: var(--primary) 0% 0% no-repeat padding-box;
    padding: 0px 0px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    cursor: pointer;
}

.logout_div ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.logout_div ul li {
    font-weight: normal;
    text-align: left;
    color: #646C7C;
    opacity: 1;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    font-family: 'Source Sans Pro', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.logout_div ul li img {
    width: 20px;
    height: 21px;
    margin-right: 25px;
    margin-left: 25px;
}

.logout_div ul li a {
    font-weight: 600;
    text-align: left;
    color: #646C7C;
    opacity: 1;
    height: 45px;
    line-height: 40px;
    font-size: 15px;
    font-family: 'Source Sans Pro', sans-serif;
    text-decoration: none;
    width: 100%;
    display: block;
    padding: 0px 0px;
}

.logout_div ul li a:hover {
    color: #0178E5;
    background: #161B27;
}


.logout_div ul li a span {
    width: 230px;
    visibility: visible;
    white-space: nowrap;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #646C7C;
    transition: width 1s linear, opacity 1s ease, visibility 1s ease;
    opacity: 1;
}

.logout_div ul li a:hover span {
    color: #0178E5;
}

body.body_class .logout_div ul li a span {
    width: 0;
    white-space: nowrap;
    visibility: hidden;
    transition: width .3s linear, opacity .3s ease, visibility .3s ease;
    opacity: 0;
}

.logout_div ul li a .setting_icon, .logout_div ul li a .logout_icon {
    display: inline-block;
}

.logout_div ul li a .setting_blue_icon, .logout_div ul li a .logout_blue_icon {
    display: none;
}

.logout_div ul li a:hover .setting_icon, .logout_div ul li a:hover .logout_icon {
    display: none;
}

.logout_div ul li a:hover .setting_blue_icon, .logout_div ul li a:hover .logout_blue_icon {
    display: inline-block;
}


.logout_div img {
    height: 18px;
    width: 18px;
    fill: #fff;
}

body.body_class .layout-fixed.main-sidebar {
    width: 75px;
    overflow: visible;
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
}

body.body_class .main_page_sectoin {
    margin-left: 105px;
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
}

body.body_class .accordion__button:before {
    transition: opacity .10s ease-in-out;
    -moz-transition: opacity .10s ease-in-out;
    -webkit-transition: opacity .10s ease-in-out;
    opacity: 0;
}

body.body_class .accordion__button {
    width: 75px;
    overflow: hidden;
    transition: width .3s linear, opacity .3s ease, visibility .3s ease;
}


.accordion__button span {
    width: 230px;
    visibility: visible;
    white-space: nowrap;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #646C7C;
    transition: width 1s linear, opacity 1s ease, visibility 1s ease;
    opacity: 1;
}

body.body_class .accordion__button span {
    width: 0;
    white-space: nowrap;
    visibility: hidden;
    transition: width .3s linear, opacity .3s ease, visibility .3s ease;
    opacity: 0;
}


body.body_class .accordion .accordion__panel {
    height: 0;
    padding: 0px;
    animation: fadein 0.35s ease-in;
    visibility: hidden;
}

body.body_class .logo_sidebar img.logo_main {
    display: none;
    visibility: hidden;
}

body.body_class .logo_sidebar img.logo_icon {
    display: block;
    width: auto;
    height: 45px;
    margin: auto;
    text-align: center;
}


.menu_sidebar {
    margin-top: 30px;
}

.header_section {
    background: rgb(62, 50, 130);
    background: linear-gradient(
            90deg, rgba(62, 50, 130, 1) 0%, rgba(194, 24, 213, 1) 100%);
    height: 60px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: 9;
}

.info_profile {
    position: absolute;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 7px;
}

.info_profile .img_profile {
    width: 46px;
    height: 46px;
    margin-right: 10px;
}

.info_profile .img_profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.info_profile span {
    display: block;
    text-align: left;
    color: var(--white);
    font-size: 15px;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
}


.info_dropdown {
    position: absolute;
    top: 46px;
    width: 100%;
    background: var(--secondary);
    right: -10px;
    border-radius: 4px;
    display: none;
    z-index: 9;
}

.info_dropdown a {
    display: block;
    color: var(--white);
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: relative;
}

.info_dropdown a:hover {
    background: var(--litgray);
    color: var(--litegreen);
    border-radius: 4px;
}

.info_dropdown a:hover:before {
    content: '';
    border-radius: 4px;
    background: var(--litegreen);
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
}

.info_profile:hover .info_dropdown {
    display: block;
}

.main_page_sectoin .container {
    max-width: 100%;
    padding: 0px;
}


.main_page_sectoin .container .card {
    border-radius: 3px !important;
}

.image_box {
    height: 64px;
    width: 148px;
}

.image_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 3px 8px #00000074;
    border-radius: 3px;
}
/* commented temporarily starts */
.OrderMenus .table > thead > tr > th, 
.OrderMenus .table > tbody > tr > td {
    width: 10%;
}

.OrderMenus .table > tbody > tr > td {
    vertical-align: middle;
    color: var(--secondary);
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
    padding: 15px 5px;
}

.OrderMenus .table > thead > tr > th {
    color: var(--black);
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
}

.OrderMenus .table {
    border: 0px;
    border-color: #E8E8E8;
}

.OrderMenus .table > thead {
    vertical-align: middle;
    background: #FAFAFA;
    border: 0px;
    border-color: transparent;
}


.OrderMenus .table > thead > tr > th {
    border-top: 0px;
    border-bottom: 0px;
} 
/* commented temporarily ends */
.action_box {
    text-align: right;
}

.action_box ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

}

.action_box ul li {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}

.action_box ul li svg {
    width: 18px;
    height: 18px;
}

.action_box ul li svg path {
    fill: var(--litgray);
}

.action_box ul li.active svg path {
    fill: var(--pink);
}

.react-bs-table-tool-bar {
    margin-top: -28px;
    margin-bottom: 30px;
}

.react-bs-table-tool-bar .col-4 {
    display: none;
}

.react-bs-table-tool-bar .col-8 {
    margin: auto;
    width: 360px;
}

.page-link {
    color: var(--litgray);
}

.react-bs-table-tool-bar .col-8 .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    outline: none;
    box-shadow: none;
    height: 33px;
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--litegreen);
}

.card {
    position: relative;
}

.card-header:first-child {
    background: transparent;
    width: 100%;
    position: absolute;
    border: 0px;
    text-align: left !important;
    left: 0;
    top: 0px;
    padding: 10px 20px;
}

.card-header:first-child div.title_table {
    color: var(--secondary);
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    height: 50px;
    line-height: 50px;
    position: relative;
}

.card-header:first-child span.link_table {
    background: var(--pink);
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--white);
    padding: 0px 16px;
    border-radius: 3px;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 8px;
    height: 33px;
    line-height: 33px;
}

.card-header:first-child span.link_table svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.card-body {
    margin-top: 20px;
}

.breadcrumb_section {
    padding: 0px 20px
}

.breadcrumb_section ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.breadcrumb_section ul li, .breadcrumb_section ul li a {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    color: rgba(255, 255, 255, 0.65);
}

.breadcrumb_section ul li a:hover {
    color: var(--litegreen);
}

.breadcrumb_section ul li:after {
    content: '/';
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.65);
    font-size: 12px;
}

.breadcrumb_section ul li:last-child:after {
    display: none;
}

.breadcrumb_section h1 {
    display: block;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--white);
    margin: 15px 0px 30px;
}

.small_img .image_box {
    height: 64px;
    width: 64px;
}

.check_box input {
    width: 18px;
    height: 18px;
}

.content_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content_title .title_h4 {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--white);
}

.img_gallery {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    flex-wrap: wrap;
    height: 70vh;
    overflow-y: auto;
}

.img_gallery div {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
    position: relative;

}

.img_gallery::-webkit-scrollbar {
    width: 0;
}

.img_gallery div img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.img_gallery div h4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    text-transform: capitalize;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--white);
    z-index: 9;
    cursor: pointer;
}

.img_gallery div:after {
    content: '';
    background: #000;
    opacity: 0.36;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 15px;
}

.top_header {
    border-bottom: 1px solid #E1E1E1;
}

.drop_down select {
    width: 240px;
    height: 32px;
    background: var(--white);
    border-radius: 4px;
    border: 0px;
    padding: 0px 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #0C1F41;
    outline: none;
    box-shadow: none;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    border: 1px solid #E1E1E1;
}

.left_side {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 75px;
}

.right_side {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 75px;
}

.left_side .drop_down h2 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 36px;
    color: #2D4047;
    text-transform: capitalize;
}

.left_side .drop_down {
    margin-right: 15px;
}

.right_side .notification-icon {
    cursor: pointer;
    margin-right: 20px;
}

.data_title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #0C1F41;
}

.profile-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.profile_img {
    width: 45px;
    height: 45px;
    margin-right: 10px;

}

.profile_img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.profile-icon .name_profile {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #0C1F41;
}

.profile-icon .name_profile span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #0C1F41;
    display: block;
}

.main_panel {
    padding-top: 30px;
}

.main_panel h1 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    color: #0C1F41;
    margin-bottom: 20px;
}

.main_panel h1 span {
    font-weight: 900;
}

.div_search .from_search {
    position: relative;
}

.div_search .from_search input {
    width: 100%;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    color: #B6BCC2;
    outline: none;
    box-shadow: none;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    height: 50px;
    padding-left: 50px;
}

.div_search .from_search button {
    background: transparent;
    width: 50px;
    height: 50px;
    border: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div_search .from_search button img {
    width: 15px;
    height: 15px;
}

.title_content {
    margin-bottom: 30px;
}

.div_search .from_search button:focus {
    outline: none;
    box-shadow: none;
}

.sales_overview {
    position: relative;
    margin-bottom: 30px;
}

.sales_overview span.sort {
    position: absolute;
    top: 0px;
    right: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #051C39;
}

.sales_overview span.sort select {
    background: transparent;
    border: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0178E5;
    margin-left: 5px;
    outline: none;
    box-shadow: none;
    text-align: center;
}

.sales_overview h2 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
    margin-bottom: 20px;
}

.bg_white {
    background: var(--white);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 30px;
}

.sales_box {
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.border__2.bg_white .col:nth-last-child(2) .sales_box, .border__2.bg_white .col:last-child .sales_box {
    border-bottom: 0px solid #F6F6F6;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.border__1.bg_white .col:last-child .sales_box {
    border-bottom: 0px solid #F6F6F6;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.bg_white .col {
    position: relative;
}

.border__2.bg_white .col:first-child:after {
    content: '';
    background: #F6F6F6;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
}

.border__2.bg_white .col:nth-child(3):after {
    content: '';
    background: #F6F6F6;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
}

.icon_box {
    height: 58px;
    width: 58px;
    background: #F5F5F5;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.title_box {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #B6BCC2;
}

.title_box span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    display: block;

}

.title_box.blue span {
    color: #0178E5;
}

.title_box.yellow span {
    color: #FCBB36;
}

.title_box.red span {
    color: #F5697A;
}

.title_box.green span {
    color: #6EC9A8;
}

.sale_count.plus:after {
    content: '';
    background-image: url("../src/assets/icons/plus.svg");
    width: 10px;
    height: 6px;
    position: absolute;
    left: -20px;
    top: 2px;
}

.sale_count.minus:after {
    content: '';
    background-image: url("../src/assets/icons/minus.svg");
    width: 10px;
    height: 6px;
    position: absolute;
    left: -20px;
    top: 5px;
    transform: rotate(-180deg);
}

.sale_count {
    position: absolute;
    top: 5px;
    right: 20px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;

}

.sale_count.minus {
    color: #F5697A;
}

.sale_count.plus {
    color: #6EC9A8;
}

.title_box span svg {
    margin-right: 10px;
    margin-top: -2px;
}

.title_box.red span svg circle, .title_box.red span svg path {
    stroke: #F5697A;
}

.title_box.green span svg circle, .title_box.green span svg path {
    stroke: #6EC9A8;
}

.title_box.blue span svg circle, .title_box.blue span svg path {
    stroke: #0178E5;
}

.title_box.yellow span svg circle, .title_box.yellow span svg path {
    stroke: #FCBB36;
}

.react-tabs__tab {
    min-width: 150px;
    text-align: center;
    border: 0px;
    border-radius: 0px;
    height: 50px;
    background: transparent;
    line-height: 33px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #DEDEDE;
    padding: 0px 30px;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #E2E2E2;
}

.react-tabs__tab:focus {
    box-shadow: none;
    border-color: none;
    outline: none;
}

.react-tabs__tab--selected {
    min-width: 150px;
    text-align: center;
    border: 0px;
    border-radius: 0px;
}

.react-tabs__tab--selected {
    border-bottom: 3px solid #0178E5;
    font-weight: 600;
    color: #0C1F41;
}

.title_total {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #8C8C8C;
    position: relative;
    margin: 30px;
    width: 300px;
}

.title_total span.count {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #252525;
    display: inline-block;
    margin-left: 20px;
}

.export_csv {
    position: absolute;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.export_csv img {
    margin-right: 10px;
}

.export_csv input {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #0C1F41;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
}

.canvasjs-chart-credit {
    display: none;
}

.resposive_issue {
    height: 240px;
}

#canvasjs-react-chart-container-5 {
    height: 240px !important;
}

.div_fillter .from_search {
    position: relative;
}

.div_fillter .from_search input {
    width: 100%;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #293A58;
    outline: none;
    box-shadow: none;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    height: 33px;
    padding-left: 30px;
    margin-right: 10px;
}

.div_fillter .from_search button {
    background: transparent;
    width: 33px;
    height: 33px;
    border: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div_fillter .from_search button img {
    width: 12px;
    height: 12px;
}


.div_fillter .from_search button:focus {
    outline: none;
    box-shadow: none;
}

.div_fillter form {
    display: flex;
    justify-content: left;
    align-items: center;
}

.div_fillter .from_search {
    width: 25%;
    margin-right: 10px;
}

.div_fillter .date_img, .div_fillter .select_staus {
    width: 15%;
    margin-right: 10px;
}

.div_fillter .select_staus:last-child {
    margin-right: 0px;
}

.div_fillter form input[type = "date"] {
    width: 100%;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #293A58;
    outline: none;
    box-shadow: none;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    height: 33px;
    margin-right: 10px;
}

.div_fillter select {
    width: 100%;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #293A58;
    outline: none;
    box-shadow: none;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    height: 33px;
    padding-left: 30px;
    margin-right: 10px;
}

.select_staus {
    position: relative;
}

.date_staus:before {
    background-image: url("assets/icons/status.png");

}

.order_platform:before {
    background-image: url("assets/icons/orderplatform.png");
}

.save_fillter:before {
    background-image: url("assets/icons/saved_fillter.png");
}

.more_fillter:before {
    background-image: url("assets/icons/morefillter.png");
}
.sales_report_icon:before {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.1655 14.5998H0.834265L0.436035 8.10889H14.5637L14.1655 14.5998Z' stroke='%237A8080' stroke-width='0.8' stroke-miterlimit='10'/%3E%3Cpath d='M10.2209 3.00002C10.2209 4.43638 9.00727 5.6182 7.4902 5.6182C5.97313 5.6182 4.77844 4.45456 4.77844 3.00002C4.77844 1.56365 5.9921 0.381836 7.50916 0.381836C9.02623 0.381836 10.2209 1.56365 10.2209 3.00002Z' stroke='%237A8080' stroke-width='0.8' stroke-miterlimit='10'/%3E%3Cpath d='M4.45618 6.45453C5.32849 5.92726 6.37147 5.61816 7.49031 5.61816C9.00738 5.61816 10.3727 6.1818 11.3968 7.09089' stroke='%237A8080' stroke-width='0.8' stroke-miterlimit='10'/%3E%3Cpath d='M3.58386 7.09095C3.84935 6.85459 4.1338 6.63641 4.45618 6.45459' stroke='%237A8080' stroke-width='0.8' stroke-miterlimit='10'/%3E%3Cpath d='M0.549805 9.81836H14.4499' stroke='%237A8080' stroke-width='0.8' stroke-miterlimit='10'/%3E%3Cpath d='M9.25391 13.0728H12.5346' stroke='%237A8080' stroke-width='0.8' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
}
.date_range_icon:before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 4.6665C2 3.56193 2.89543 2.6665 4 2.6665H12C13.1046 2.6665 14 3.56193 14 4.6665V12.6665C14 13.7711 13.1046 14.6665 12 14.6665H4C2.89543 14.6665 2 13.7711 2 12.6665V4.6665Z' stroke='%237A8080' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.6667 1.3335V4.00016' stroke='%237A8080' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.33325 1.3335V4.00016' stroke='%237A8080' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2 6.6665H14' stroke='%237A8080' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.select_staus:before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: 9px;
    left: 10px;
    display: block;
    z-index: 9;
    background-size: 100%;
    background-position: center center;
}

.border-bottom_1 {
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 30px;
    margin-bottom: 30px;

}

.tabs_content.order_tabs {
    position: relative;
}

.tabs_content.order_tabs .card-header:first-child {
    display: none;
}

.tabs_content.order_tabs .card {
    padding: 0px !important;
    border: 0px !important;
}

.tabs_content.order_tabs .bg-white {
    margin-top: 0px !important;
}

.tabs_content.order_tabs .card-body {
    margin-top: 0;
    padding: 0px;
    background: #f7f7f7;
}

.tabs_content.order_tabs .table > thead {
    background: #F0F0F0;
}

.tabs_content.order_tabs .table > thead tr {
    border-width: 0px;
    padding: 0px;
    height: 50px;
    text-align: center;
}


.tabs_content.order_tabs .table-bordered > :not(caption) > * {
    border-width: 1px 0;
    border-color: #E5E5E5;
}


.tabs_content.order_tabs .table-responsive > .table-bordered {
    margin-bottom: 0px;
}

.tabs_content.order_tabs .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: #F0F0F0;
}

.tabs_content.order_tabs .table > tbody > tr > td {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
    padding: 0px;
    height: 70px;
    text-align: center;
}

.tabs_content.order_tabs .table > tbody > tr > td:first-child {
    font-weight: 600;
}

.tabs_content.order_tabs .table > tbody > tr > td span a {
    color: #646C7C;
}

.tabs_content.order_tabs .page-link {
    text-decoration: none;
    background-color: transparent !important;
    border: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #2F3E46 !important
}

.tabs_content.order_tabs .page-link.sr-only {
    text-decoration: none;
    background-color: transparent;
    border: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #2F3E46;
    outline: none;
    box-shadow: none;
}

.page-link:focus {
    outline: none;
    box-shadow: none;
}

.tabs_content.order_tabs .page-link:first-child, .tabs_content.order_tabs .page-link:last-child {
    text-decoration: none;
    background-color: transparent;
    border: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #2F3E46;
    outline: none;
    box-shadow: none;
}


.tabs_content.order_tabs .page-link:hover {
    color: #0178E5 !important;
    font-weight: 600;
}

.time_at span, .amount span {
    display: block;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #0178E5;
}

.tabs_content.order_tabs .table-responsive {
    display: inline-table;
}

.new_order {
    background: #EFEFEF;
    color: #4D5464;
}

.late_order {
    background: #FFE4E8;
    color: #F5697A;
}

.on-delivery {
    background: #FFF7E5;
    color: #FCBB36;
}

.completed {
    background: #E6FFF6;
    color: #6EC9A8;
}

.prepairing {
    background: #EFF7FF;
    color: #0178E5;

}

.order-stats {
    border-radius: 5px;
    display: inline-block;
    max-width: 80%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: auto;
    padding: 0px 15px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.tabs_content.order_tabs .col-sm-4 div {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
}

.print_icon {
    position: absolute;
    right: 0px;
    top: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0178E5;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    padding: 5px 8px;
    border: 1px solid #0178E5;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
}

.print_icon svg {
    margin-right: 10px;
}

.tabs_content.order_tabs .table > tbody > tr > td {
    position: relative;
}

.tabs_content.order_tabs .table > tbody > tr > td:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50%;
    height: 15px;
    width: 1px;
    background: #E9E9E9;
    transform: translate(-50%, -50%);
}

.tabs_content.order_tabs .table > tbody > tr > td:last-child:after {
    display: none;
}

#printid tbody > tr > td:first-child div.checkbox input, #printid thead > tr > th:first-child div.checkbox input {
    display: none;
}

#printid.active tbody > tr > td:first-child div.checkbox input, #printid.active thead > tr > th:first-child div.checkbox input {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    top: 2px;
    left: 5px;
}

#printshow {
    display: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    flex: none;
    background: #0178E5;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
    padding: 5px 8px;
    border: 1px solid #0178E5;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
}

#printshow svg {
    margin-right: 10px;
}

#printshow svg path {
    fill: #fff;

}

#printshow.active {
    display: inline-block;
}

.user_info {
    padding: 40px 50px;
    margin: 0px;
    list-style: none;
    display: flex;
}

.user_info li {
    margin-right: 50px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #646C7C;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.user_info li img {
    margin-right: 10px;
}

.order_status {
    padding: 0px;
    margin: 0px;
}

.order_status h3 {
    padding: 0px 50px 15px;
    border-bottom: 1px solid #E1E1E1;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
    display: block;
    position: relative;
}

.order_status h3:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background: #0178E5;
    position: absolute;
    bottom: 0px;
    left: 40px;
    width: 120px;
}

.order_status ul {
    padding: 30px 50px;
    margin: 0px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_status ul li {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #0178E5;
    position: relative;
}

.order_status ul li:after {
    content: '';
    width: 1px;
    height: 40px;
    display: block;
    background: #E3E3E3;
    position: absolute;
    top: 50%;
    right: -50px;
    transform: translate(-50%, -50%);
}

.order_status ul li:last-child:after {
    display: none;
}

.order_status ul li span {
    display: block;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #0C1F41;
    margin-top: 5px;
    display: block;
}

.order_listing {
    padding: 30px 50px;
}

.order_listing h3 {
    padding: 0px 10px 15px;
    border-bottom: 1px solid #E1E1E1;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
    display: block;
    position: relative;
    margin-bottom: 20px;
}

.order_listing h3:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background: #0178E5;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 120px;
}

.item_list ul {
    padding: 20px 20px;
    margin: 0px;
    list-style: none;
    display: flex;
    justify-content: left;
    align-items: center;

}

.item_list ul li {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
}

.item_list ul li {
    flex-basis: 15%;
    text-align: center;
}

.item_list ul li:first-child {
    flex-basis: 15%;
    font-weight: 600;
    text-align: left;

}

.item_list ul li:nth-child(2) {
    flex-basis: 55%;
    text-align: left;
}

.listing_items .item_list:first-child ul li {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
}

.listing_items .item_list:nth-child(odd) ul {
    background: #F0F0F0;
}

.listing_items .item_list:nth-child(even) ul {
    background: #fff;
}

.order_sub_total {
    display: flex;
    justify-content: end;
    align-content: center;
}

.order_sub_total ul {
    padding: 20px 20px;
    margin: 0px;
    list-style: none;
    flex-basis: 25%;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 33px;
    color: #646C7C;
}

.order_sub_total ul li {
    margin-bottom: 5px;
}

.order_sub_total ul li span {
    display: block;
    float: right;
    width: 120px;
}

.order_sub_total ul li:last-child {
    margin-top: 30px;
    font-weight: 600;
    line-height: 50px;
}

.order_sub_total ul li:last-child span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 33px;
    color: #4D5464;
    width: 120px;
}

.menu_listing {
    padding: 20px 0px;
    position: relative;
}

.menu_listing h3 {
    padding: 0px 10px 15px;
    border-bottom: 1px solid #E1E1E1;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
    display: block;
    position: relative;
    margin-bottom: 20px;
}

.menu_listing h3:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background: #0178E5;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 120px;
}

.category_breadcrumb {
    position: absolute;
    right: 0px;
    top: 25px;
}

.category_breadcrumb ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.category_breadcrumb ul li {
    margin-left: 40px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #DEDEDE;
    position: relative;
}

.category_breadcrumb ul li:before {
    content: '';
    background: #DEDEDE;
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 8px;
    left: -20px;
    border-radius: 50px;
}

.category_breadcrumb ul li.active:before {
    background: #6EC9A8;
}

.category_breadcrumb ul li.active {
    color: #6EC9A8;
}

.category_items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
}

.menu_category {
    margin-bottom: 20px;
}

.menu_category .category_items.thead_category_items {
    background: #F0F0F0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
    margin-bottom: 0px;
}

.category_items {
    background: #FFF;
    margin-top: 10px;
}

.subcategory.category_items, .category_items.sub_cat {
    margin-top: 1px;
}

.category_items.sub_cat div.items:nth-child(2) {
    padding-left: 50px;
}

.category_items.sub_cat.item div.items:nth-child(2) {
    padding-left: 80px;
}

.category_items div {
    flex-basis: 10%;
    text-align: left;
    padding: 0px 15px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
    position: relative;
}

.category_items div.items:after {
    content: '';
    width: 1px;
    height: 20px;
    background: #E9E9E9;
    display: inline-block;
    position: absolute;
    top: 55%;
    right: 0px;
    transform: translate(-55%, -55%);
}

.category_items div.items:last-child:after {
    display: none;

}

.category_items div.items:nth-last-child(2):after {
    right: -10px;
}

.category_items div.items:first-child {
    flex-basis: 5%;
    text-align: left;
}

.category_items div.items:last-child {
    flex-basis: 30%;
    text-align: left;
}

.category_items div.items:nth-child(2) {
    flex-basis: 35%;
    text-align: left;
}

.category_items input {
    border: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #0178E5;
}

.category_items.sub_cat input {
    color: #646C7C;
}

.category_items.sub_cat.item input {
    color: #A7A7A7;
}

.category_items div.react-switch {
    padding: 0px;
}

.category_items div.react-switch-bg {
    background: #F7F7F7 !important;
    border: 1px solid #EEEEEE !important;


}

.react-switch div.react-switch-handle {
    padding: 0px;
    box-shadow: none !important;

}

.react-switch.active div.react-switch-handle {
    background: #DEDEDE !important;
}

.item_counts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 47px;
    background: #fff;
    padding: 0px 10px;
}

.item_counts .counting {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
}

.item_counts .result_page {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
}

.item_counts .result_page select {
    background: #fff;
    margin-left: 15px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
    padding: 0px 5px;
}

.action_fillter {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.action_fillter li {
    margin-right: 20px;
    position: relative;
    cursor: pointer;
}

.category_items.sub_cat.item .action_fillter li:first-child div {
    opacity: 0;
    visibility: hidden;
}

.category_items.sub_cat.item div.items:nth-last-child(2):after {
    opacity: 0;
    visibility: hidden;
}

.action_fillter li:after {
    content: '';
    width: 1px;
    height: 20px;
    background: #E9E9E9;
    display: inline-block;
    position: absolute;
    top: 0px;
    right: -10px;
}

.action_fillter li:last-child:after {
    display: none;
}

.category_items div.items .plus_action {
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
}

.category_items div.items .plus_action svg rect {
    fill: #6E7584;
}

.category_items div.items .plus_action:hover svg rect {
    fill: #0178E5;
}


.action_fillter li svg circle {
    fill: #DEDEDE;
}


.action_fillter li:hover svg path, .action_fillter li:hover svg rect, .action_fillter li:hover svg circle {
    fill: #0178E5;
}

.action_fillter li:hover .white svg path:last-child {
    fill: #F7F7F7;
}

.add_category {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #0178E5;
    cursor: pointer;
    margin-top: 30px;
    display: block;
}

.bottom_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination_list {
    padding: 0px;
    margin: -45px 0px 0px 0px;
    list-style: none;
    display: flex;
    justify-content: end;
    align-items: center;
    height: 33px;
}

.pagination_list li {
    margin-right: 15px;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
}


.pagination_list li:first-child svg {
    margin-right: 15px;
}

.pagination_list li:first-child:after {
    content: '';
    width: 1px;
    height: 15px;
    display: block;
    background: #333;
    position: absolute;
    top: 4px;
    right: -15px;
}

.pagination_list li:last-child:after {
    content: '';
    width: 1px;
    height: 15px;
    display: block;
    background: #333;
    position: absolute;
    top: 4px;
    left: -15px;
}

.pagination_list li:last-child svg {
    margin-left: 15px;
}


.pagination_list li:first-child, .pagination_list li:last-child {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #2F3E46;
}

.pagination_list li:hover {
    color: #0178E5;
}

.pagination_list li:nth-child(2) {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 10px;
    width: 41px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #2F3E46;
}

.pagination_list li:nth-child(3) {
    margin: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #7B7F80;
}

.pagination_list li:nth-last-child(2) {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #2F3E46;
}

.sub__title svg {
    transform: rotate(180deg);
    margin-right: 20px;
}

.sub__title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
    display: flex;
    justify-content: start;
    align-items: center;
    min-height: 33px;
}

.title_content .div_search .from_search input {
    height: 33px;
    width: 300px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #A9B0B0;
    padding-left: 40px;
}

.title_content.div__search {
    margin-bottom: 0px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.title_content .div_search .from_search button {
    background: transparent;
    width: 40px;
    height: 33px;
    border: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}


.back_to_home {
    margin-left: 10px;
}

.back_to_home a {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 33px;
    color: #293A58;
    height: 33px;
    background: #fff;
    display: inline-block;
    padding: 0px 20px;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    border: 1px solid #E1E1E1;
    border-radius: 4px;
}

.back_to_home a:hover {
    color: #0178E5;
    font-weight: 400;
}

.product_details {
    padding: 10px 0 30px 0;
    margin-top: 30px;
}

.tab_froms {
    padding: 30px 30px;
    width: 80%;
}

.tab_froms .form-check-input {
    position: relative;
    margin-left: 0px;
}

.tab_froms .form-label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #4D5464;
    margin-bottom: 5px;
}

.tab_froms .form-label svg {
    margin-left: 15px;
}

.tab_froms input, .tab_froms select {
    background: #F0F0F0;
    border-radius: 5px;
    height: 50px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #646C7C;
    border-color: #F0F0F0;
}

.tab_froms .div_select {
    position: relative;
}

.tab_froms .div_select:after {
    content: '';
    background-image: url("assets/icons/arrow_down.svg");
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    right: 20px;
    bottom: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.tab_froms input:focus {
    outline: none;
    box-shadow: none;
}

.tab_froms textarea {
    background: #F0F0F0;
    border-radius: 5px;
    height: 90px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #646C7C;
}

.tab_froms .form-select:focus, .tab_froms input:focus, .tab_froms textarea:focus {
    border-color: #0178E5;
    outline: 0;
    box-shadow: none;
}

.tab_froms .form-control:focus {
    background: #F0F0F0;
}

.tab_froms .div_from {
    margin-bottom: 20px;
}

.tab_froms .form-check-input {
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: transparent;
    border: 1px solid #0178e5;
    transition: all 0.5s ease;
}

.form-check-input:checked[type=checkbox]{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%230178E5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
    background-color: transparent;
    border-color: #0178E5;
    outline: none;
    box-shadow: none;
    transition: all 0.5s ease;
}

.file_icon {
    display: flex;
    justify-content: start;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
}

.file_icon .img_box {
    background: #F0F0F0;
    width: 46px;
    height: 46px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.file_icon .text_box {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
}

.file_icon .text_box span {
    display: block;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #DEDEDE;
}

.custom_file {
    position: relative;
    margin-top: 30px;
}

.custom_file input[type="file"] {
    opacity: 0;
    height: 46px;
    font-size: 0px;
    width: 100%;
    position: relative;
    z-index: 9;
}

.img_show {
    margin: 20px 0px;
    display: flex;
    justify-content: left;
    align-items: center;
    display: none;
}

.img_show.active {
    display: flex;
}

.img_show img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
}

.btn_submit {
    background: #0178E5;
    border-radius: 5px;
    margin-right: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    height: 33px;
    min-width: 90px;
}

/* MBK work starts here */

/* 
*
* Settings Page
*
*/
.breadcrumbs {
    position: relative;
}
.breadcrumbs ul{
    position: relative;
    margin-bottom: 0;
}
.breadcrumbs ul li{
    position: relative;
    margin-right: 1rem !important;
    font-size: 14px;
    color: #646C7C;
}
.breadcrumbs ul li a{
    color: #646C7C;
}
.breadcrumbs ul li a:hover, .breadcrumbs ul li a:focus{
    color: #0178E5;
    transition: all 0.5s ease;
}
.breadcrumbs ul li:not(:last-child)::after{
    content: '>';
    position: absolute;
    right: -12px;
    font-size: 14px;
}
.productSearch .btn_back{
    height: 33px;
    background-color: #fff;
    color: #000;
    font-size: 12px;
    padding: 8px 22px;
    border-radius: 5px;
    transition: all .5s ease;
}
.productSearch .btn_back:hover, .productSearch .btn_back:focus{
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, .20);
    transition: all .5s ease;
}
.productSearch .form_product_search{
    width: unset;
}
.productSearch form{
    justify-content: right;
}
.settingsTabs{
    
}
.settingsTabs .react-tabs__tab-list{
    margin-bottom: 0;
}
.settingsTabs .react-tabs__tab-panel{
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}
.settingsTabs .react-tabs__tab{
    width: unset;
    min-width: unset;
    height: unset;
    padding: 10px 40px;
    font-size: 15px;
    line-height: 18px;
}
.settingsTabs .react-tabs__tab.react-tabs__tab--selected{
    padding: 10px 40px 10px 5px;
}
.subSettingsTabs{
    
}
.subSettingsTabs hr{
    margin: 0;
}
.subSettingsTabs .react-tabs__tab-list{
    border-bottom: none;
    padding: 5px 20px;
}
.subSettingsTabs .react-tabs__tab{
    padding: 8px 20px;
}
.subSettingsTabs p.sm-text{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    color: #646C7C;
}
.subSettingsTabs .react-tabs__tab.react-tabs__tab--selected{
    background: #0178E5;
    padding: 8px 20px;
    border-bottom: none;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #ffff;
}
.subSettingsTabs .react-tabs__tab-panel{
    padding: 20px;
}
.boxTimings{
    margin-bottom: 20px;
    position: relative;
    /* background: red; */
    padding: 10px;
}
.boxTimings .boxTimingsHeader{
    border-bottom: 1px solid #efefef;
    
}
.boxTimings .boxTimingsHeader .timingTitle{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    display: inline-block;
    padding: 5px 0;
    /* identical to box height */
    color: #0C1F41;
    border-bottom: 3px solid #0178E5;
}
.boxTimings .boxTimingsBody{
    padding-top: 20px;
    padding-bottom: 20px;
}
.boxTimings .boxTimingsBody .dayStatus{
    display: block;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 10px;
    /* identical to box height */
    color: #0178E5;
}
.boxTimings .boxTimingsBody .dayTime,
.boxTimings .boxTimingsBody .dayDate{
    display: block;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    /* or 100% */

    color: #0C1F41;
}
.boxTimings .boxTimingsBody .dayTime small,
.boxTimings .boxTimingsBody .dayDate small{
    font-size: 60%;
}
.quoteTimeBox{
    position: relative;
    /* background: red; */
}
.quoteTimeBox .quoteTimeBoxHeader .timingTitle{
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */


    color: #0178E5;
}
.quoteTimeBox .quoteTimeBoxHeader .timingDescription{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */

    color: #0C1F41;
}
.quoteTimeBox .quoteTimeBoxHeader hr.blueLine{
    margin-bottom: 10px;
    /* border-bottom: 3px solid #0178e5; */
    opacity: 1;
    height: 3px;
    background: #0178e5;
}
.quoteTimeBox .quoteTimeBoxBody{
    /* padding: 15px 10px; */
}
.quoteTimeBox .quoteTimeBoxBody .quoteTimes{
    display: block;
}
.quoteTimeBox .quoteTimeBoxBody .toolTip{
    width: 15px;height: 15px;
    background: #DEDEDE;
    display: inline-block;
    border-radius: 50%;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #4D5464;
    position: relative;
    /* margin: 0px auto; */
    text-align: center;
    cursor: help;
    margin-left: 10px;
}
.formQuoteTimes{
    margin-top: 20px;
    position: relative;
}
.formQuoteTimes .labelHeading{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    /* font-size: 16px; */
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 10px;

    /* 0178E5 */

    color: #0178E5;
}
.formQuoteTimes label.form-check-label {
    /* font-size: 12px; */
    font-size: 10px;
}
.formQuoteTimes .form-check-input[type=checkbox]{
    border-color: #0178e5;
}
.formQuoteTimes .form-check-input[type=checkbox]:checked{
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%230178e5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.formQuoteTimes .form-select{
    background-color: #F0F0F0;
    border-color: #F0F0F0;
    border-radius: 5px;
    /* font-size: 14px;
    padding: .375rem 1.25rem .375rem .65rem; */
    font-size: 10px;
    padding: 0.375rem 0.95rem 0.375rem 0.25rem;
    background-position: right 0.25rem center;
    background-size: 10px 8px;
}
.availabilityTabs{
    position: relative;
    clear: both;
}
.availabilityTabs .availabilityTabList{
    position: relative;
    width: 25%;
    margin-left: 0;
    padding-left: 0;
    float: left;
    /* height: 500px;
    overflow-y: scroll; */
}
.availabilityTabs .availabilityTabList .react-tabs__tab{
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    border-radius: unset;
    padding: 20px 15px;
    border-left: 3px solid transparent;
    transition: all 0.5s ease;
}
.availabilityTabs .availabilityTabList .react-tabs__tab span.headingTab{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: #0178E5;

}
.availabilityTabs .availabilityTabList .react-tabs__tab p{
    margin-bottom: 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    margin-top: 5px;
    /* or 175% */


    /* 646C7C */

    color: #646C7C;
}
.availabilityTabs .availabilityTabList .react-tabs__tab.react-tabs__tab--selected{
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    border-radius: unset;
    margin-bottom: 0;
    border-left: 3px solid #0178e5;
    transition: all 0.5s ease;
}
.availabilityTabPanels{
    width: 75%;
    float: left;
}
.availabilityTabs .availabilityTabList .react-tabs__tab::after {
    content: ' ';
    position: absolute;
    height: 2px;
    width: 60%;
    display: block;
    background: #efefef;
    margin: 0px auto;
    text-align: center;
    bottom: 0;
    left: 15%;
    /* transform: translate(50%, 50%); */
}
.availabilityTabs  .availabilityTabPanels{
    border-left: 2px solid #efefef;
}
.availabilitySubTabs{
    
}
.availabilitySubTabs .availabilitySubTabsList{
    position: relative;
    width: 30%;
    margin-left: 0;
    padding-left: 0;
    float: left;
    /* height: 500px;
    overflow-y: scroll; */
}
.availabilitySubTabs .availabilitySubTabsList .react-tabs__tab{
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    border-radius: unset;
    padding: 8px 10px;
    border-left: 3px solid transparent;
    transition: all 0.5s ease;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #646C7C;
}
.availabilitySubTabs .availabilitySubTabsList .react-tabs__tab.react-tabs__tab--selected{
    display: block;
    width: 100%;
    color: #0178E5;
    text-align: left;
    background: transparent;
    border: none;
    border-radius: unset;
    margin-bottom: 0;
    border-left: 3px solid #0178e5;
    transition: all 0.5s ease;
}
.availabilitySubTabs .availabilitySubPanels{
    border-left: 2px solid #efefef;
    position: relative;
    width: 70%;
    float: left;
    /* height: 100%; */
    /* height: 500px; */
    height: 590px;
    padding: 10px 15px;
    display: none;
}
.availabilitySubTabs .availabilitySubPanels.react-tabs__tab-panel--selected {
    display: block;
}
.formToggleOnlineOrdering .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
    border-color: #DEDEDE;
}
.formToggleOnlineOrdering .form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%230178E5'/%3e%3c/svg%3e");
}
.formToggleOnlineOrdering .form-check-input:checked {
    background-color: transparent;
    border-color: #DEDEDE;
    outline: none;
    box-shadow: none;
}
/*
*
* Payments Page
*
*/
.paymentsPage{
    position: relative;
}
.paymentsPage .category_breadcrumb {
    top: 20px;
}
.paymentsPage .btnNewEntry{
    font-size: 12px;
    font-family: Source Sans Pro;
    font-style: normal;
    background: #0178E5;
    border-radius: 5px;
    color: #fff;
    transition: all 0.5s ease;
}
.paymentsPage .btnNewEntry:hover{
    font-size: 12px;
    font-family: Source Sans Pro;
    font-style: normal;
    background: #000;
    border-color: #000;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    transition: all 0.5s ease;
}
.paymentsPage .viewRecord{
    background: none;
    border: none;
    padding: unset;
}
.paymentsPage .viewRecord:hover svg path, .paymentsPage .viewRecord:focus svg path{
    fill: #0178E5;
    transition: all 0.5s ease;
}
.paymentsPage .labelStatus{
        padding: 5px 10px;
        display: inline-block;
        border-radius: 5px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        border-radius: 5px;
        text-transform: capitalize;
}
.paymentsPage .labelStatus.statusPaid{
        color: #6EC9A8;
        background: #E6FFF6;
}
.paymentsPage .labelStatus.statusPending{
        color: #FCBB36;
        background: #FFF7E5;
}
.paymentsPage .labelStatus.statusFailed{
        color: #F5697A;
        background: #FFE4E8;
}
.paymentReceipt{
    background: #fff;
    position: relative;
    padding: 22px 15px;
}
.paymentReceipt .clientDetails{}
.paymentReceipt .clientDetails li{
    margin-right: 10px;
    font-size: 18px;
    line-height: 18px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #646C7C;
}
.paymentReceipt .clientDetails li svg{
    margin-right: 10px;
}
.paymentReceipt .category_breadcrumb {
    top: 15px;
    right: 15px;
}
.paymentReceipt .btnPrint{
    font-size: 12px;
    font-family: Source Sans Pro;
    font-style: normal;
    background: #0178E5;
    border-radius: 5px;
    color: #fff;
    transition: all 0.5s ease;
}
.paymentReceipt .btnPrint svg{
    margin-right: 5px;
}
.paymentReceipt .btnPrint:hover{
    font-size: 12px;
    font-family: Source Sans Pro;
    font-style: normal;
    background: #000;
    border-color: #000;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    transition: all 0.5s ease;
}
.paymentReceipt .paymentReceiptInfoBox{
    position: relative;
}
.paymentReceipt .paymentReceiptInfoBox label{
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
    display: block;
    /* identical to box height */


    color: #0178E5;
}
.paymentReceipt .paymentReceiptInfoBox p{
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    /* font-size: 20px;
    line-height: 24px; */
    font-size: 15px;
    line-height: 20px;
    /* or 140% */


    color: #0C1F41;
}
.paymentReceipt .paymentReceiptInfoBox p.inputDisplay {
    background: #F0F0F0;
    border-radius: 5px;
    display: inline-block;
    padding: 10px 30px 10px 10px;
}
.paymentReceipt .paymentReceiptInfoBox p.inputDisplay.Cash {
    width: 107px;
}
.paymentReceipt .paymentReceiptInfoBox p.inputDisplay.TextHash {
    width: 191px;
}
.paymentReceipt h3:after {
    width: 15%;
}
.paymentReceipt .tableReceipt .table{
    border-color: transparent;
}
.paymentReceipt .tableReceipt .table thead{
    background: #F0F0F0;
    border: 0px;
    border-color: transparent;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
}
.paymentReceipt .tableReceipt .table > thead > tr > th{
    border: none;
}
.paymentReceipt .tableReceipt .table>:not(:last-child)>:last-child>*{
    border: none;
}
.paymentReceipt .tableReceipt .table tbody tr:nth-child(even){
    background: #F0F0F0;
}
.paymentReceipt .tableReceipt .table > tbody > tr > td,
.paymentReceipt .tableReceipt .table > tbody > tr > th,
.paymentReceipt .tableReceipt .table > tfoot > tr > td,
.paymentReceipt .tableReceipt .table > tfoot > tr > th,
.paymentReceipt .tableReceipt .table > thead > tr > td,
.paymentReceipt .tableReceipt .table > thead > tr > th {
    vertical-align: middle;
    /* width: auto;
    height: auto; */
}
.paymentReceipt .tableReceipt .table tfoot tr td {
    padding: 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 33px;
    color: #646C7C;
}
.paymentReceipt .tableReceipt .table tfoot .TotalRow td {
    padding: 8px 0px;
}
.paymentReceipt .tableReceipt .highlightTotal {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 33px;
    color: #4D5464;
}

/* 
*
* Reports views
*
*/
.reportsView{
    position: relative;
}
.reportsView .reportBoxTitle{
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #4D5464;
}
.reportsView .reportBox{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
}
.reportsView .reportBox .labelTitle{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #B6BCC2;
    margin-bottom: 0;
}
.reportsView .reportBox .labelTotalLg{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24.4507px;
    line-height: 31px;
    color: #B6BCC2;
}
.reportsView .reportBox p{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0;
}
.reportsView .reportBox p.textTotalLg{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    /* font-size: 34.9296px;
    line-height: 44px; */
    font-size: 26px;
    line-height: 27px;
}
/* color switch class for report box */
.reportsView .reportBox.textBlue svg path{
    
}
.reportsView .reportBox.textBlue p{
    color: #0178E5;
}
.reportsView .reportBox.textOrange svg path{
    
}
.reportsView .reportBox.textOrange p{
    color: #FCBB36;
}
.reportsView .reportBox.textGreen svg path{
    
}
.reportsView .reportBox.textGreen p{
    color: #6EC9A8;
}
.reportsView .reportBox.textRed svg path{
    
}
.reportsView .reportBox.textRed p{
    color: #F5697A;
}
.reportsView .reportBox.textGrey svg path{
    
}
.reportsView .reportBox.textGrey p{
    color: #646C7C;
}
.tabsReportSales{
    position: relative;
}
.tabsReportSales .react-tabs__tab-list{
    margin-bottom: 0;
}
.tabsReportSales .react-tabs__tab-list .react-tabs__tab{
    height: unset;
    min-width: unset;
    padding: 8px 30px;
    transition: all 0.5s ease;
}
.tabsReportSales .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected{
    padding-left: 0;
    text-align: left;
    transition: all 0.5s ease;
}
.reportingOverviewBox{
    position: relative;
    padding: 15px 10px;
}
.reportingOverviewBox:not(:last-child){
    border-width: 0 1px 1px 0;
    border-color: #EFEFEF;
    border-style: solid;
}
.reportingOverviewBox:last-child{
    border-width: 0 0 1px 0;
    border-color: #EFEFEF;
    border-style: solid;
}
.reportingOverviewBox label{
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    color: #0178E5;
}
.reportingOverviewBox p{
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #0C1F41;
    margin-bottom: 0;
}
.tabsReportSales .tabsReportSalesTabsPanel{
    opacity: 0;
    display: none;
    transition: all 0.5s ease;
}
.tabsReportSales .tabsReportSalesTabsPanel.react-tabs__tab-panel--selected{
    opacity: 1;
    display: block;
    transition: all 0.5s ease;
}

/* 
*
* create profile - Decide Role
*
*/
.DecideRole{
    background: #1C212D;
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
}
.DecideRole .greetings h4{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 50px;

    color: #FFFFFF;
}
.DecideRole .radio-control{
    position: relative;
    margin-bottom: 15px;
    display: block;
}
.DecideRole .radio-control input[type="radio"]{
    display: none;
    opacity: 0;
}
.DecideRole .radio-control label{
    position: relative;
    font-family: Source Sans Pro;
    background: #242831;
    border-radius: 10px;
    color: #fff;
    display: block;
    width: 100%;
    line-height: 26px;
    padding: 25px 15px;
    font-size: 15px;
    max-height: 80px;
    cursor: pointer;
    transition: all 0.5s ease;
}
.DecideRole .radio-control label:after{
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761204 13.8268C0.258657 12.6136 -1.95685e-08 11.3132 0 10C3.95203e-08 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34783 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM15.2311 5.88C15.1279 5.77653 15.0053 5.69443 14.8703 5.63842C14.7353 5.5824 14.5906 5.55357 14.4444 5.55357C14.2983 5.55357 14.1536 5.5824 14.0186 5.63842C13.8836 5.69443 13.761 5.77653 13.6578 5.88L7.77778 11.7622L6.34222 10.3244C6.23891 10.2211 6.11627 10.1392 5.9813 10.0833C5.84632 10.0274 5.70165 9.9986 5.55555 9.9986C5.40946 9.9986 5.26479 10.0274 5.12981 10.0833C4.99484 10.1392 4.8722 10.2211 4.76889 10.3244C4.66558 10.4278 4.58363 10.5504 4.52773 10.6854C4.47182 10.8203 4.44304 10.965 4.44304 11.1111C4.44304 11.2572 4.47182 11.4019 4.52773 11.5369C4.58363 11.6718 4.66558 11.7945 4.76889 11.8978L6.99111 14.12C7.09432 14.2235 7.21694 14.3056 7.35192 14.3616C7.48691 14.4176 7.63163 14.4464 7.77778 14.4464C7.92393 14.4464 8.06864 14.4176 8.20363 14.3616C8.33862 14.3056 8.46123 14.2235 8.56444 14.12L15.2311 7.45333C15.3346 7.35012 15.4167 7.22751 15.4727 7.09252C15.5287 6.95753 15.5575 6.81282 15.5575 6.66667C15.5575 6.52052 15.5287 6.3758 15.4727 6.24082C15.4167 6.10583 15.3346 5.98321 15.2311 5.88Z' fill='%236EC9A8'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 35%;
    opacity: 0;
    transition: all 0.5s ease;
}
.DecideRole .radio-control input[type="radio"]:checked + label, 
.DecideRole .radio-control label:hover,
.DecideRole .radio-control label:focus{
    background: #2E3442;
    transition: all 0.5s ease;
}
.DecideRole .radio-control label:hover:after,
.DecideRole .radio-control label:focus:after{
    opacity: 1;
    transition: all 0.5s ease;
}

.DecideRole .radio-control label svg{
    margin-right: 15px;
}
.DecideRole .radio-control input[type="radio"]:checked + label:after{
   opacity: 1;
   transition: all 0.5s ease;
}
.DecideRole .nextOptions{
    position: absolute;
    /* width: 85%; */
    width: 20%;
    display: block;
    /* background: red; */
    bottom: 0;
}
.DecideRole .nextOptions .btn-blue{
    font-family: Source Sans Pro;
    background: #0178E5;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    /* width: 132px; */
    width: 110px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.DecideRole .nextOptions .btn-blue:hover, .DecideRole .nextOptions .btn-blue:focus{
    
}
.DecideRole .nextOptions p{
    font-family: Source Sans Pro;
    font-size: 15px;
    color: #ffffff;
    margin-top: 20px;
}
.DecideRole .nextOptions p a{
    font-family: Source Sans Pro;
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
}

.DecideRole ul.list-absolute-left {
    position: absolute;
    right: 30px;
    top: 45%;
}
.DecideRole ul.list-absolute-left li{
    list-style: none;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #fff;
}
.DecideRole ul.list-absolute-left li a{
    font-size: 15px;
    color: #fff;
}
.DecideRole ul.list-absolute-left li::marker{
    display: none;
}
.DecideRole ul.list-absolute-left li::before{
    content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%23C4C4C4'/%3E%3C/svg%3E");
    position: absolute;
    right: -20px;
}
.DecideRole ul.list-absolute-left li.markDone a{
    color: #6EC9A8;
}
.DecideRole ul.list-absolute-left li.markDone::before{
    /* content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%23C4C4C4'/%3E%3C/svg%3E"); */
    content: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761204 13.8268C0.258657 12.6136 -1.95685e-08 11.3132 0 10C3.95203e-08 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34783 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM15.2311 5.88C15.1279 5.77653 15.0053 5.69443 14.8703 5.63842C14.7353 5.5824 14.5906 5.55357 14.4444 5.55357C14.2983 5.55357 14.1536 5.5824 14.0186 5.63842C13.8836 5.69443 13.761 5.77653 13.6578 5.88L7.77778 11.7622L6.34222 10.3244C6.23891 10.2211 6.11627 10.1392 5.9813 10.0833C5.84632 10.0274 5.70165 9.9986 5.55555 9.9986C5.40946 9.9986 5.26479 10.0274 5.12981 10.0833C4.99484 10.1392 4.8722 10.2211 4.76889 10.3244C4.66558 10.4278 4.58363 10.5504 4.52773 10.6854C4.47182 10.8203 4.44304 10.965 4.44304 11.1111C4.44304 11.2572 4.47182 11.4019 4.52773 11.5369C4.58363 11.6718 4.66558 11.7945 4.76889 11.8978L6.99111 14.12C7.09432 14.2235 7.21694 14.3056 7.35192 14.3616C7.48691 14.4176 7.63163 14.4464 7.77778 14.4464C7.92393 14.4464 8.06864 14.4176 8.20363 14.3616C8.33862 14.3056 8.46123 14.2235 8.56444 14.12L15.2311 7.45333C15.3346 7.35012 15.4167 7.22751 15.4727 7.09252C15.5287 6.95753 15.5575 6.81282 15.5575 6.66667C15.5575 6.52052 15.5287 6.3758 15.4727 6.24082C15.4167 6.10583 15.3346 5.98321 15.2311 5.88Z' fill='%236EC9A8'/%3E%3C/svg%3E%0A");
    position: absolute;
    right: -20px;
    
}
.DecideRole ul.list-absolute-left li.markCurrent a{
    color: #0178E5;
}
.DecideRole ul.list-absolute-left li.markCurrent::before{
    content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%230178E5'/%3E%3C/svg%3E%0A");
    position: absolute;
    right: -20px;
    
}
.DecideRole .accessTab{
    position: relative;
    background: #2E3442;
    border-radius: 10px 10px 0 0;
}
.DecideRole .accessTab .accessTabHeader{
    background: #252A36;
    border-radius: 10px;
    padding: 8px 15px;
}
.DecideRole .accessTab .accessTabHeader h4{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.DecideRole .accessTab .accessTabBody{
    padding: 20px 15px;
}
.DecideRole .accessTab .accessTabBody .accessGivenBox{
    background: #383F4F;
    border-radius: 10px;
    /* padding: 8px 15px; */
    padding: 20px 15px;
    /* width: 430px;
    height: 79px; */
    margin-bottom: 10px;
}
.DecideRole .accessTab .accessTabBody .accessGivenBox p{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    /* font-size: 22px;
    line-height: 28px; */
    font-size: 18px;
    line-height: 28px;
    /* identical to box height */
    margin-bottom: 0;

    color: #FFFFFF;
}
.DecideRole .accessTab .accessTabBody .form-check{
    margin-bottom: 11px;
}
.DecideRole .accessTab .accessTabBody .form-check input{
    border: 1px solid #fff;
    background-color: transparent;
    border-radius: 0;
    width: 1.1em;
    height: 1.1em;
    cursor: pointer;
}
.DecideRole .accessTab .accessTabBody .form-check input:checked[type=checkbox]{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%230178E5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
    border: 1px solid #0178E5;
}
.DecideRole .accessTab .accessTabBody .form-check label{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 23px;
    margin-left: 10px;
    /* identical to box height */
    cursor: pointer;

    color: #FFFFFF;
}
.DecideRole .img-profile img{
    width: 163px;
    height: 163px;
    border-radius: 50%;
    object-fit: cover;
}
.DecideRole .img-profile .profileDetails{
    display: inline-block;
    margin-left: 15px;
    /* margin-top: 20px; */
    /* background: red; */
    vertical-align: middle;
}
.DecideRole .img-profile .profileDetails p{
    margin-bottom: 0;
    font-size: 11px;
    /* display: block; */
    margin-left: 20px;
}
.DecideRole .img-profile .profileDetails h5{
    margin-bottom: 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 161.3%;
    /* or 40px */


    color: #FFFFFF;
}
.DecideRole .editProfileLink{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;
}
.DecideRole .editProfileLink:hover,
.DecideRole .editProfileLink:focus{
    color: #0178E5;
    transition: all 0.5s ease;
}
.DecideRole .custom-formControls{
    position: relative;
}
.DecideRole .custom-formControls label{
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 20px;
    display: block;
}
.DecideRole .custom-formControls .form-control{
    position: relative;
    background: #2E3442;
    border-radius: 10px;
    border: none;
    height: 55px;
    color: #fff;
}
.DecideRole .custom-formControls textarea.form-control{
    height: unset;
    color: #fff;
}
.DecideRole .btn-submit{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;



    width: 132px;
    height: 40px;
    position: absolute;
    /* left: 495px;
    top: 984px; */

    background: #0178E5;
    border-radius: 5px;
}

/*
*
* Products Page
*
*/
.tableModifiers {
    position: relative;
}
.tableModifiers thead tr th,
.tableModifiers tbody tr td{
    text-align: left;
}
.tabs_content.order_tabs .tableModifiers > thead > tr > th{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0C1F41;
    vertical-align: middle;
}
.tabs_content.order_tabs .tableModifiers > tbody > tr{
    border-color: transparent;
}
.tabs_content.order_tabs .tableModifiers > tbody > tr > td {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #646C7C;
    padding: 0.5rem 1rem;
    height: unset;
    text-align: left;
    vertical-align: middle;
}
.inputMargin input{
    margin: 0;
}
.btnAddModifiers{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    background: none;
    padding: 0;
    border: none;
    display: inline-block;

    /* 0178E5 */
    margin-bottom: 20px;
    color: #0178E5;
}
.btnAddModifiers:hover, 
.btnAddModifiers:focus{
    background: none;
    padding: 0;
    border: none;
    color: #0178E5;
    text-decoration: underline;
    
    transition: all 0.5s ease;
}
.btn-AddTax{
    background: #0178E5;
    border-radius: 5px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */


    color: #FFFFFF;

    padding: 8px 10px;
    display: inline-block;
    float: none;
    height: 40px;
    margin: -8px 0 0 10px;
}
.selectTable{
    display: inline-block;
    width: 50%;
    height: 40px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-color: #f0f0f0;
    border-color: #f0f0f0;
}

/*
*
* Menu views
*
*/
.radioForm input{
    position: relative;
    margin: 0;
}
.groupActiveFilter label{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */


    /* 646C7C */

    color: #646C7C;
}
.groupActiveFilter input{
    background-color: transparent;
    border-color: #646C7C;
}
.list-actions{
    position: relative;
    margin-left: 0;
}
.list-actions li{
    position: relative;
    margin-right: 10px;
    padding: 10px;
}
.list-actions li:before {
    content: '';
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    left: 0px;
    border: 1px solid #E9E9E9;
}

/* experimental class */
.vh70-white{
    height: 70vh;
    background: #fff;
    border-radius: 10px;
}
/* experimental class */
.customMenus{
    position: relative;
}
.customMenus h3:after{
    width: 180px;
}
.customMenus .tab_froms{
    width: 100%;
}
.customMenus .file_icon{
    position: relative;
}
.customMenus .custom_file label{
    cursor: pointer;
}
.customMenus .custom_file .labelFileButton{
    cursor: pointer;
    padding: 8px 15px;
    background: #0178E5;
    border-radius: 5px;
    margin-right: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}
.customMenus .form-check{
    padding-left: 0;
    margin-bottom: 10px;
}
.customMenus .form-check .form-check-input{
    float: none;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0;
}
.customMenus .btn-addNewItem{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    background: none;
    border: none;
    padding: 0;
    margin-top: 20px;

    color: #0178E5;
}

/* custom menus edit */
.tab_froms .subFormLabel{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    /* 0178E5 */

    color: #0178E5;
}
.formInline{
    display: flex;
    flex-direction: row;
}
.formInline .div_select:after{
    right: 5px;
    bottom: 10px;
}
.formInline .form-select{
    padding: 0.375rem 0.25rem 0.375rem 0.75rem;
}
.formInline input, .formInline select{
    width: 66px;
    height: 29px;
    background: #F0F0F0;
    border-radius: 5px;
}
.formInline2{
    display: flex;
    /* flex-basis: 200px; */
    align-content: center;
    justify-content: center;
    align-items: center;
}
.formInline2 .custom-width{
    width: 165px;
    height: 35px !important;
}
.customMenus .form-check-label{
    font-size: 14px;
    line-height: 24px;
}
.customMenus .form-check-label-inlineFloat label{
    display: inline;
    margin-left: 30px;
    line-height: 20px;
    margin-top: -28px;
    /* background: red; */
    float: left;
}
.customMenus .form-bg-search {
    margin-left: 30px;

}
.customMenus .form-bg-search .form-control{
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6.83333' cy='6.83333' r='5.83333' stroke='%230178E5' stroke-width='2'/%3E%3Cpath d='M15.0002 15L13.4447 13.4444L12.6669 12.6666L11.1113 11.1111' stroke='%230178E5' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M10.333 11.8888C11.471 11.752 11.8241 11.4168 11.8886 10.3333' stroke='%230178E5' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    padding-left: 40px;
    background-repeat: no-repeat;
    /* width: 298px; */
    height: 33px;
    background-position: 10px center;
}
.customMenus .productTagsContainer {
    /* background: red; */
    margin-left: 30px;
}
.customMenus .productTagsContainer .productTag{
    background: #F0F0F0;
    border-radius: 40px;
    display: inline-block;
    padding: 7px 40px 7px 17px;
    /* width: 129px; */
    /* height: 33px; */
    position: relative;
    margin-bottom: 10px;
    margin-right: 10px;
}
.customMenus .productTagsContainer .productTag span{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #4D5464;
}
.customMenus .productTagsContainer .productTag svg{
    position: absolute;
    right: 5px;
    top: 26%;
}
.customMenus .formInline3 label{
    display: inline;
    margin-right: 5px;
}
.customMenus .formInline3 input{
    display: inline-block;
    width: 80px;
    height: 35px;
    margin-right: 10px;
}
.customMenus .formInline4 label{
    display: inline;
    margin-right: 5px;
}
.customMenus .formInline4 input{
    display: inline-block;
    width: 105px;
    height: 35px;
    margin-right: 10px;
}

/* menu fixes in aziz's work */
.category_items.subcategory {
    margin-top: 8px;
}